import { Link } from "gatsby";
import React from "react";

import {
  Box,
  Button,
  Flex,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";

import { MdBuild, MdCall } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import { ChevronDownIcon } from "@chakra-ui/icons";

//Transforms the Button to a Gatsby Link component
const NavButton = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Button
      as={Link}
      to={to}
      {...rest}
      // mb={{ base: isLast ? 0 : 8, sm: 0 }}
      // mr={{ base: 0, sm: isLast ? 0 : 8 }}
    >
      {children}
    </Button>
  );
};

//Transforms the MenuItem to a Gatsby Link component
const NavMenuItem = ({ children, to = "/", ...rest }) => {
  return (
    <MenuItem as={Link} to={to} {...rest} zIndex="99999">
      {children}
    </MenuItem>
  );
};

const Navbar = ({ showNavBar }) => (
  <Box
    //Hides the navbar or shows it when icon is clicked
    display={{ base: showNavBar ? "block" : "none", md: "block" }}
    flexBasis={{ base: "100%", md: "auto" }}
    //added to make the spacer component work on bigger views
    flex={[null, null, 1, null]}
    zIndex={["base", "base"]}
  >
    <Flex
      as="nav"
      // align="center"
      justify={["center", "space-between", "flex-end", "flex-end"]}
      direction={["column", "row", "row", "row"]}
      pt={[4, 4, 0, 0]}
    >
      <Spacer />
      <Menu>
        <MenuButton
          as={Button}
          colorScheme="cyan"
          rightIcon={<ChevronDownIcon />}
        >
          More info
        </MenuButton>
        <MenuList>
          <MenuGroup title="Info">
            <NavMenuItem to="/mission">What is our mission?</NavMenuItem>
            <NavMenuItem to="/faq">FAQ </NavMenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="Other noteworthy creatures">
            <NavMenuItem to="/ratbadger">Ratbadger</NavMenuItem>
            <NavMenuItem to="http://www.manbearpig.net/">
              Manbearpig
            </NavMenuItem>
            <NavMenuItem to="https://www.outsideonline.com/2097161/10-most-convincing-bigfoot-sightings">
              Bigfoot
            </NavMenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      <Spacer />
      <NavButton
        to="/legend"
        // leftIcon={<FiMapPin />}
        colorScheme="cyan"
        variant="solid"
      >
        The legend of the Bearbat
      </NavButton>
      <Spacer />
      {/* <NavButton
        to="/stories"
        // leftIcon={<FiMapPin />}
        colorScheme="cyan"
        variant="solid"
      >
        Stories
      </NavButton>
      <Spacer /> */}
      <NavButton
        to="/map"
        leftIcon={<FiMapPin />}
        colorScheme="red"
        variant="solid"
      >
        Map
      </NavButton>
    </Flex>
  </Box>
);

export default Navbar;
