import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import logo from "../images/icon.png";

import {
  Box,
  Flex,
  Square,
  Center,
  Text,
  Icon,
  Spacer,
  Heading,
  GridItem,
  Image,
  Stack,
} from "@chakra-ui/react";
import { FiMapPin } from "react-icons/fi";

const Logo = (props) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "blueIcon.png" }) {
        childImageSharp {
          fluid(maxWidth: 128) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Link to="/">
      <Stack direction={["column", "row"]} {...props} alignItems="center">
        <Box w="10vh">
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            alt="Bearbat logo"
          />
        </Box>
        <Heading
          bgGradient="linear(to-r, blue.700, red.300)"
          bgClip="text"
          fontSize="2xl"
          fontWeight="extrabold"
          // fontSize="lg"
          // fontWeight="bold"
        >
          BearBat
        </Heading>
      </Stack>
    </Link>
  );
};

export default Logo;
