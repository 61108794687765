import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import {
  // Box,
  // Flex,
  // Link,
  // Grid,
  // GridItem,
  // Center,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";

import Header from "./header";
// import Main from "./main";
import Footer from "./footer";

const Layout = (props) => {
  return (
    <SimpleGrid
      // templateRows="0.5fr 8fr 1fr"
      // columns={[1, null, 2]}
      gap={8}
      // spacing="40px"
      {...props}
      bgGradient={[
        "linear(to-b, blue.100,cyan.900)",
        // "linear(to-t, blue.200, teal.500)",
        // "linear(to-b, orange.100, purple.300)",
      ]}
    >
      <Header />

      {props.children}
      <Footer />
    </SimpleGrid>
  );
};

export default Layout;
