// import { Link } from "gatsby"
import React from "react";

import { Box, Flex, Link, GridItem, Center } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const Footer = () => (
  <Box as="footer" color="blue.200">
    <Center
      mx="2rem"
      my="6rem"
      // bg="pink.100"
    >
      © {new Date().getFullYear()}, Built by
      <Link href="https://www.diederik.pro" isExternal>
        Diederik <ExternalLinkIcon mx="2px" />
      </Link>
    </Center>
  </Box>
);

export default Footer;
